import React, { useState, Suspense, useEffect,lazy } from 'react';
import { graphql, Link } from 'gatsby';

import '@aws-amplify/ui-react/styles.css';
import { fetchUserAttributes,getCurrentUser} from "@aws-amplify/auth";
import { StairazAuth } from '../../components/userAuth.js';
import { StaticImage } from "gatsby-plugin-image"
import * as styles from './index.module.css';
import { FaCaretDown } from 'react-icons/fa';
import { useTheme } from '../../context/ThemeContext';
import { FaSun, FaMoon,FaChevronLeft } from 'react-icons/fa';


const Dashboard = ({ data,signOut, user }) => {
    const [selectedItem, setSelectedItem] = useState('Dashboard');
    const [selectedSubItem, setSelectedSubItem] = useState('');
    const [dropdownOpenItem, setDropdownOpenItem] = useState('');
    const [userProfileData,setUserProfileData] = useState(null);
    const [initial, setInitial] = useState('');
    const { isDarkMode, toggleMode } = useTheme();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    };

    const createUserProfileData =  async () =>{
        try{

            const userData = await fetchUserAttributes();
            setUserProfileData(userData)
       
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
          }




    }

    useEffect(() => {   
        
        createUserProfileData() 
    
    
    
    }, []);

    useEffect(() => {
        if (userProfileData && userProfileData.given_name && userProfileData.family_name) {
          const initial = `${userProfileData.given_name.charAt(0).toUpperCase()}${userProfileData.family_name.charAt(0).toUpperCase()}`;
          setInitial(initial);
        }
      }, [userProfileData]);

    useEffect(() => {
        
        const handleHashChange = () => {
          const hash = window.location.hash.substring(1);
          const [item, subItem] = hash.split('&');
          if (item) setSelectedItem(item);
          if (subItem) setSelectedSubItem(subItem);
        };
    
        handleHashChange();
        window.addEventListener('hashchange', handleHashChange);
    
        return () => {
          window.removeEventListener('hashchange', handleHashChange);
        };
        
      }, []);

      
    


    const Dashboard = lazy(() => import('../../service/dashboard/main.js'));
	const Resume = lazy(() => import('../../service/resumeManagement/main.js'));
    const CoverLetter = lazy(() => import('../../service/coverLetterManagement/main.js'));

	const InterviewQABank = lazy(() => import('../../service/interviewQABank/main.js'));

    const menuItems = window.innerWidth > 768 ? [
        { name: 'Dashboard' },
        { name: 'Resume' },
		{ name: 'CoverLetter' },
        { 
            name: 'Interview',
            subItems: ['QuestionBank', 'MockInterviews'] 
        },
    ] :[
        { name: 'Dashboard' },
        { name: 'Resume' },
		{ name: 'CoverLetter' },
       
    ]
    ;

    const formatMenuItemName = (name) => {
        return name.replace(/([a-z])([A-Z])/g, '$1 $2');
    };

    const handleItemClick = (item) => {
        if (item.subItems) {
          setDropdownOpenItem(dropdownOpenItem === item.name ? '' : item.name);
          setSelectedItem(item.name);
          setSelectedSubItem(''); // Clear sub-item selection when main item is selected
          updateUrlFragment(item.name, '');
        } else {
          setSelectedItem(item.name);
          setDropdownOpenItem(''); // Close dropdown when another item is selected
          setSelectedSubItem(''); // Clear sub-item selection
          updateUrlFragment(item.name, '');
        }
      };
    
      const handleSubItemClick = (subItem) => {
        setSelectedSubItem(subItem);
        setSelectedItem(dropdownOpenItem); // Ensure main item is also highlighted
        updateUrlFragment(dropdownOpenItem, subItem);
      };
    
      const updateUrlFragment = (item, subItem) => {
        const newHash = `${item}${subItem ? `&${subItem}` : ''}`;
        window.history.pushState(null, '', `#${newHash}`);
      };

	const renderContent = () => {
        switch (selectedItem) {
            case 'Dashboard':
                return <Suspense fallback={<div>Loading...</div>}><Dashboard data={data} user={user}/></Suspense>;
            case 'Resume':
                return <Suspense fallback={<div>Loading...</div>}><Resume user={user}/></Suspense>;
            case 'CoverLetter':
                return <Suspense fallback={<div>Loading...</div>}><CoverLetter user={user}/></Suspense>;
            case 'Interview':
                if (selectedSubItem === 'QuestionBank') {
                    return <Suspense fallback={<div>Loading...</div>}><InterviewQABank /></Suspense>;
                } else if (selectedSubItem === 'MockInterviews') {
                    return <div>Mock Interviews Content</div>;
                } else {
                    return <div>Please select a sub-item</div>;
                }
            default:
                return <div>Welcome to the Dashboard</div>;
        }
    };

    

    return (
        <div className={`${styles.dashboard} ${isDarkMode ? styles.darkMode : styles.lightMode}`}>
             <div className={styles.userIconContainer}>
            
             <div className={styles.userInitialCircle}>{initial}</div>
                <button className={styles.userProfileButton} onClick={toggleDropdown}>
                
                    {userProfileData ? userProfileData.given_name:''} <FaCaretDown className={`${styles.dropdownArrow} ${isDropdownOpen ? styles.open : ''}`} />
                </button>
                {isDropdownOpen && (
                    <div className={styles.userProfileDropdownMenu}>
                    <div className={styles.item} onClick={signOut}>Logout</div>
                    </div>
                )}
                      <div className={`${styles.switchButton} ${isDarkMode ? styles.dark : styles.light}`} onClick={toggleMode}>
                        <div className={styles.sunIcon}>
                            <FaSun />
                        </div>
                        <div className={styles.moonIcon}>
                            <FaMoon />
                        </div>
                        <div className={styles.switchCircle}></div>
                        </div>
                </div>
                <div className={`${styles.sidebar} ${isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed}`}>
                <a href='/career/' target='_blank' className={styles.logo}>
                    <StaticImage
                        src='../../images/logo.png'
                        alt="STAIRAZ Logo: illustrate the idea of AI."
                        width={80}
                        height={80}
                    />
                    <div className={styles.logoText}>STAIRAZ</div>
                </a>
                <div className={styles.dividerHorizontal}></div>
                <div className={styles.menu}>
                    {menuItems.map((item) => (
                        <div key={item.name}>
                        <div
                            className={`${styles.menuItem} ${(selectedItem === item.name && !selectedSubItem) || (item.name === dropdownOpenItem) ? styles.selected : ''} ${item.subItems ? styles.dropdownMenu : ''} ${item.subItems && dropdownOpenItem === item.name ? styles.dropdownItemSelected : ''}`}
                            onClick={() => handleItemClick(item)}
                        >
                            <div className={styles.menuText}>{formatMenuItemName(item.name)}</div>
                        </div>
                        {item.subItems && dropdownOpenItem === item.name && (
                            <div className={`${styles.dropdown} ${dropdownOpenItem === item.name ? styles.dropdownSelected : ''}`}>
                                {item.subItems.map((subItem) => (
                                    <div
                                        key={subItem}
                                        className={`${styles.dropdownItem} ${selectedSubItem === subItem ? styles.selected : ''}`}
                                        onClick={() => handleSubItemClick(subItem)}
                                    >
                                        <div className={styles.menuText}>{formatMenuItemName(subItem)}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    
                    ))}
                </div>
                <div className={styles.dividerHorizontal}></div>
                <button className={styles.signOut} onClick={signOut}>Sign out</button>
               
            </div>
            <button className={styles.toggleButton} onClick={toggleSidebar}>
                <FaChevronLeft className={`${styles.arrowIcon} ${isSidebarOpen ? styles.arrowIconOpen : styles.arrowIconClosed}`} />
            </button>
          
            <div className={styles.contentArea}>
                <div className={styles.navbar}>
                <p className={styles.serviceTitle}>
                    {formatMenuItemName(selectedSubItem) ? formatMenuItemName(selectedSubItem) : formatMenuItemName(selectedItem)}
                </p>

                </div>
                <div className={styles.mainContent}>
                {renderContent()}
                </div>
            </div>
        </div>
    );
};


export default StairazAuth(Dashboard);
